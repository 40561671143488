/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * An enum for designating country names.
 */
export enum CountryCodeNames
{
	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ABW = 'Aruba',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	AFG = 'Afghanistan',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	AGO = 'Angola',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	AIA = 'Anguilla',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ALA = 'Åland Islands',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ALB = 'Albania',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	AND = 'Andorra',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ARE = 'United Arab Emirates',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ARG = 'Argentina',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ARM = 'Armenia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ASM = 'American Samoa',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ATA = 'Antarctica',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ATF = 'French Southern Territories',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ATG = 'Antigua and Barbuda',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	AUS = 'Australia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	AUT = 'Austria',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	AZE = 'Azerbaijan',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BDI = 'Burundi',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BEL = 'Belgium',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BEN = 'Benin',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BES = 'Bonaire Sint Eustatius and Saba',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BFA = 'Burkina Faso',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BGD = 'Bangladesh',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BGR = 'Bulgaria',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BHR = 'Bahrain',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BHS = 'Bahamas',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BIH = 'Bosnia and Herzegovina',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BLM = 'Saint Barthélemy',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BLR = 'Belarus',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BLZ = 'Belize',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BMU = 'Bermuda',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BOL = 'Bolivia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BRA = 'Brazil',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BRB = 'Barbados',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BRN = 'Brunei Darussalam',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BTN = 'Bhutan',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BVT = 'Bouvet Island',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	BWA = 'Botswana',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CAF = 'Central African Republic',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CAN = 'Canada',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CCK = 'Cocos (Keeling) Islands',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CHE = 'Switzerland',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CHL = 'Chile',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CHN = 'China',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CIV = 'Côte d\'Ivoire',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CMR = 'Cameroon',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	COD = 'Congo Democratic Republic',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	COG = 'Congo',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	COK = 'Cook Islands',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	COL = 'Colombia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	COM = 'Comoros',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CPV = 'Cabo Verde',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CRI = 'Costa Rica',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CUB = 'Cuba',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CUW = 'Curaçao',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CXR = 'Christmas Island',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CYM = 'Cayman Islands',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CYP = 'Cyprus',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	CZE = 'Czechia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	DEU = 'Germany',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	DJI = 'Djibouti',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	DMA = 'Dominica',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	DNK = 'Denmark',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	DOM = 'Dominican Republic',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	DZA = 'Algeria',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ECU = 'Ecuador',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	EGY = 'Egypt',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ERI = 'Eritrea',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ESH = 'Western Sahara',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ESP = 'Spain',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	EST = 'Estonia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ETH = 'Ethiopia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	FIN = 'Finland',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	FJI = 'Fiji',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	FLK = 'Falkland Islands',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	FRA = 'France',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	FRO = 'Faroe Islands',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	FSM = 'Micronesia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GAB = 'Gabon',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GBR = 'United Kingdom',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GEO = 'Georgia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GGY = 'Guernsey',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GHA = 'Ghana',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GIB = 'Gibraltar',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GIN = 'Guinea',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GLP = 'Guadeloupe',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GMB = 'Gambia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GNB = 'Guinea-Bissau',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GNQ = 'Equatorial Guinea',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GRC = 'Greece',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GRD = 'Grenada',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GRL = 'Greenland',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GTM = 'Guatemala',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GUF = 'French Guiana',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GUM = 'Guam',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	GUY = 'Guyana',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	HKG = 'Hong Kong',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	HMD = 'Heard Island and McDonald Islands',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	HND = 'Honduras',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	HRV = 'Croatia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	HTI = 'Haiti',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	HUN = 'Hungary',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	IDN = 'Indonesia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	IMN = 'Isle of Man',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	IND = 'India',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	IOT = 'British Indian Ocean Territory',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	IRL = 'Ireland',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	IRN = 'Iran',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	IRQ = 'Iraq',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ISL = 'Iceland',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ISR = 'Israel',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ITA = 'Italy',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	JAM = 'Jamaica',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	JEY = 'Jersey',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	JOR = 'Jordan',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	JPN = 'Japan',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	KAZ = 'Kazakhstan',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	KEN = 'Kenya',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	KGZ = 'Kyrgyzstan',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	KHM = 'Cambodia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	KIR = 'Kiribati',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	KNA = 'Saint Kitts and Nevis',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	KOR = 'Korea Republic of',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	KWT = 'Kuwait',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	LAO = 'Lao',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	LBN = 'Lebanon',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	LBR = 'Liberia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	LBY = 'Libya',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	LCA = 'Saint Lucia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	LIE = 'Liechtenstein',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	LKA = 'Sri Lanka',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	LSO = 'Lesotho',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	LTU = 'Lithuania',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	LUX = 'Luxembourg',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	LVA = 'Latvia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MAC = 'Macao',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MAF = 'Saint Martin',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MAR = 'Morocco',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MCO = 'Monaco',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MDA = 'Moldova',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MDG = 'Madagascar',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MDV = 'Maldives',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MEX = 'Mexico',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MHL = 'Marshall Islands',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MKD = 'North Macedonia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MLI = 'Mali',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MLT = 'Malta',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MMR = 'Myanmar',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MNE = 'Montenegro',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MNG = 'Mongolia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MNP = 'Northern Mariana Islands',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MOZ = 'Mozambique',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MRT = 'Mauritania',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MSR = 'Montserrat',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MTQ = 'Martinique',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MUS = 'Mauritius',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MWI = 'Malawi',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MYS = 'Malaysia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	MYT = 'Mayotte',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	NAM = 'Namibia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	NCL = 'New Caledonia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	NER = 'Niger',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	NFK = 'Norfolk Island',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	NGA = 'Nigeria',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	NIC = 'Nicaragua',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	NIU = 'Niue',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	NLD = 'Netherlands',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	NOR = 'Norway',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	NPL = 'Nepal',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	NRU = 'Nauru',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	NZL = 'New Zealand',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	OMN = 'Oman',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	PAK = 'Pakistan',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	PAN = 'Panama',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	PCN = 'Pitcairn',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	PER = 'Peru',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	PHL = 'Philippines',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	PLW = 'Palau',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	PNG = 'Papua New Guinea',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	POL = 'Poland',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	PRI = 'Puerto Rico',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	PRK = 'Korea Democratic People\'s Republic',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	PRT = 'Portugal',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	PRY = 'Paraguay',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	PSE = 'Palestine',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	PYF = 'French Polynesia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	QAT = 'Qatar',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	REU = 'Réunion',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ROU = 'Romania',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	RUS = 'Russian Federation',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	RWA = 'Rwanda',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SAU = 'Saudi Arabia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SDN = 'Sudan',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SEN = 'Senegal',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SGP = 'Singapore',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SGS = 'South Georgia and South Sandwich Islands',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SHN = 'Saint Helena Ascension and Tristan da Cunha',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SJM = 'Svalbard and Jan Mayen',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SLB = 'Solomon Islands',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SLE = 'Sierra Leone',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SLV = 'El Salvador',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SMR = 'San Marino',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SOM = 'Somalia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SPM = 'Saint Pierre and Miquelon',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SRB = 'Serbia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SSD = 'South Sudan',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	STP = 'Sao Tome and Principe',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SUR = 'Suriname',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SVK = 'Slovakia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SVN = 'Slovenia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SWE = 'Sweden',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SWZ = 'Eswatini',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SXM = 'Sint Maarten',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SYC = 'Seychelles',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	SYR = 'Syrian Arab Republic',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	TCA = 'Turks and Caicos Islands',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	TCD = 'Chad',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	TGO = 'Togo',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	THA = 'Thailand',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	TJK = 'Tajikistan',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	TKL = 'Tokelau',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	TKM = 'Turkmenistan',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	TLS = 'Timor-Leste',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	TON = 'Tonga',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	TTO = 'Trinidad and Tobago',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	TUN = 'Tunisia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	TUR = 'Türkiye',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	TUV = 'Tuvalu',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	TWN = 'Taiwan',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	TZA = 'Tanzania',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	UGA = 'Uganda',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	UKR = 'Ukraine',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	UMI = 'United States Minor Outlying Islands',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	URY = 'Uruguay',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	USA = 'United States of America',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	UZB = 'Uzbekistan',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	VAT = 'Holy See',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	VCT = 'Saint Vincent and the Grenadines',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	VEN = 'Venezuela',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	VGB = 'Virgin Islands British',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	VIR = 'Virgin Islands U.S.',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	VNM = 'Viet Nam',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	VUT = 'Vanuatu',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	WLF = 'Wallis and Futuna',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	WSM = 'Samoa',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	YEM = 'Yemen',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ZAF = 'South Africa',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ZMB = 'Zambia',

	/**
	 * The {string} country code name.
	 *
	 * @type {string}
	 * @memberof CountryCodeName
	 */
	ZWE = 'Zimbabwe',
}