/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ITransactionKeyDates
} from '@insurance/business-logic-entities/transactions/transaction.key-dates';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	InsuranceHelper
} from '@insurance/helpers/insurance.helper';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	BusinessLogicEntity
} from '@shared/business-logic-entities/business-logic-entity';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	DateTime
} from 'luxon';

/**
 * A Business Logic Entity of Transaction.
 *
 * @export
 * @class Transaction
 */
export class Transaction
	extends BusinessLogicEntity
{
	/**
	 * Initializes a new instance of the transaction
	 * businesss logic entity.
	 *
	 * @param {IEntityInstance} transaction
	 * The underlying IWorkItem.
	 * @param {ResolverService} resolverService
	 * The resolver service.
	 * @memberof Transaction
	 */
	public constructor(
		transaction: IEntityInstance,
		public resolverService: ResolverService)
	{
		super(
			transaction,
			resolverService);

		this.insuranceService = this.resolverService
			.resolveInsurance('InsuranceService');
	}

	/**
	 * The transaction data object.
	 *
	 * @memberof Transaction
	 */
	declare public data: {
		policyNumber: string;
		transactionNumber: string;
		basedOnTransactionNumber: string;
		type: string;
		subType: string;
		status: string;
		preSubmittedStatus: string;
		effectiveDate: string;
		keyDates: ITransactionKeyDates;
		accounting: any;
		reasons: any[];
		interests: any[];
		assets: any[];
		characteristics: any;
	};

	/**
	 * Gets the Insurance Service used in methods.
	 *
	 * @private
	 * @type {InsuranceService}
	 * @memberof Transaction
	 */
	public readonly insuranceService: InsuranceService;

	/**
	 * Gets the declinable transaction types.
	 *
	 * @returns {string[]}
	 * the string array of types.
	 * @memberof Transaction
	 */
	public declinableTypes(): string[]
	{
		return InsuranceConstants.declinableTransactionTypes;
	}

	/**
	 * Gets the approvable transaction types.
	 *
	 * @returns {string[]}
	 * the string array of types.
	 * @memberof Transaction
	 */
	public approvableTypes(): string[]
	{
		return InsuranceConstants.approvableTransactionTypes;
	}

	/**
	 * Gets a value of true indicating if the user
	 * can decline. False otherwise.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * the promise holding the value.
	 * @memberof Transaction
	 */
	public async userCanDecline(): Promise<boolean>
	{
		const declinable: boolean =
			this.declinableTypes().includes(this.data.type)
				&& this.statusCheck(
					this.data.status,
					[
						InsuranceConstants.transactionStatusTypes.submitted,
						InsuranceConstants.transactionStatusTypes.bound
					]);

		if (!declinable)
		{
			return false;
		}

		return this.entityService.actionAuthorized(
			this,
			'TransactionDecline');
	}

	/**
	 * Gets the product to which this transaction belongs.
	 *
	 * @async
	 * @returns {Promise<IEntityInstance>}
	 * the promise holding the product.
	 * @memberof Transaction
	 */
	public async getProduct(): Promise<IEntityInstance>
	{
		return this.insuranceService
			.getProductByTransaction(this);
	}

	/**
	 * Gets the parent Policy Term for this transaction.
	 *
	 * @async
	 * @returns {Promise<IEntityInstance>}
	 * The promise holding the policy term.
	 */
	public async getPolicyTerm(): Promise<IEntityInstance>
	{
		const transactionType: IEntityType =
			this.insuranceService
				.entityService
				.entityTypes
				.find(
					(entityType: IEntityType) =>
						entityType.name === this.entityType);

		return this.insuranceService
			.getPolicyTermByTransaction(
				this.id,
				transactionType.group);
	}

	/**
	 * Gets a value of true indicating if the user
	 * can rescind a declined transaction. False otherwise.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * the promise holding the value.
	 * @memberof Transaction
	 */
	public async userCanRescindDeclination(): Promise<boolean>
	{
		const declined: string =
			InsuranceConstants.transactionStatusTypes.declined;

		if (this.data.status !== declined)
		{
			return false;
		}

		if (this.data.type !== InsuranceConstants.transactionTypes
			.newBusiness
			&& this.data.type !== InsuranceConstants.transactionTypes
				.endorsement)
		{
			return false;
		}

		const product: IEntityInstance =
			await this.getProduct();

		const daysAllowedToRescind: number =
			product
				.data
				.newBusiness
				.declination
				.daysToAllowRescind;

		const declineDate: DateTime = DateHelper
			.fromUtcIso(this.data.keyDates.declineDate);

		const daysElapsed: number =
			parseFloat(DateHelper
				.getDateDifference(
					declineDate,
					DateTime.now().toUTC()));

		const inTimeWindow: boolean =
			(daysAllowedToRescind - daysElapsed) > 0;

		if (!inTimeWindow)
		{
			return false;
		}

		const policyTerm: IEntityInstance =
			await this.getPolicyTerm();

		const latestTransaction: IEntityInstance =
			await this.insuranceService
				.getLatestPolicyTermTransactionByPolicyTerm(
					policyTerm.id);

		if (latestTransaction.id !== this.id)
		{
			return false;
		}

		return this.entityService
			.actionAuthorized(
				this,
				'TransactionRescindDecline');
	}

	/**
	 * Gets a value of true indicating if the user
	 * can approve. False otherwise.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * the promise holding the value.
	 * @memberof Transaction
	 */
	public async userCanApprove(): Promise<boolean>
	{
		const approvable: boolean =
			this.approvableTypes().includes(this.data.type)
				&& this.statusCheck(
					this.data.status,
					[InsuranceConstants.transactionStatusTypes.submitted]);

		if (!approvable)
		{
			return false;
		}

		return this.entityService.actionAuthorized(
			this,
			'TransactionApprove');
	}

	/**
	 * Gets a value of true if the status is both included
	 * and not excluded. False otherwise.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * the promise holding the value.
	 * @memberof Transaction
	 */
	public statusCheck(
		status: string,
		includedStatusValues: string[],
		excludedStatusValues: string[] =
		InsuranceHelper.transactionExcludedStatusValues): boolean
	{
		return InsuranceHelper
			.policyTermTransactionStatusCheck(
				status,
				includedStatusValues,
				excludedStatusValues);
	}
}