/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	Settings
} from 'luxon';

/**
 * A class containing static helper methods for api services.
 *
 * @export
 * @class ApiHelper
 */
export class ApiHelper
{
	/**
	 * RegExp to test a string for a full ISO 8601 Date
	 * Does not do any sort of date validation, only checks if the string is
	 * according to the ISO 8601 spec.
	 *  YYYY-MM-DDThh:mm:ss
	 *  YYYY-MM-DDThh:mm:ssTZD
	 *  YYYY-MM-DDThh:mm:ss.sTZD
	 * @see: https://www.w3.org/TR/NOTE-datetime
	 * @type {RegExp}
	 */
	public static readonly fullIsoExpression: RegExp =
		/\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?/ig;

	/**
	 * Queries for and returns the full data set matching the sent filter
	 * and order by. This data set will continue querying until all
	 * database values are found.
	 *
	 * @async
	 * @static
	 * @param {BaseEntityApiService<TDataType>} apiService
	 * The api service used to get a full data set.
	 * @param {string} filter
	 * The filter value that will be sent to the api service query method.
	 * @param {string} orderBy
	 * The order by value that will be sent to the api service query method.
	 * @param {number} dataLimit
	 * The number of data items to return per request.
	 * @returns {Promise<TDataType[]>}
	 * An awaitable promise that will contain the full data set of a limited
	 * result set api service.
	 * @memberof ApiHelper
	 */
	public static async getFullDataSet<TDataType>(
		apiService: BaseEntityApiService<TDataType>,
		filter: string,
		orderBy: string,
		dataLimit: number = AppConstants.dataLimits.large): Promise<TDataType[]>
	{
		let resultSet: TDataType[] =
			await apiService.query(
				filter,
				orderBy,
				0,
				dataLimit);
		let dataCount: number = resultSet.length;

		while (dataCount % dataLimit === 0)
		{
			const nestedResultSet: TDataType[] =
				await apiService.query(
					filter,
					orderBy,
					dataCount,
					dataLimit);

			if (nestedResultSet.length === 0)
			{
				break;
			}

			dataCount += nestedResultSet.length;

			resultSet =
				[
					...resultSet,
					...nestedResultSet
				];
		}

		return resultSet;
	}

	/**
	 * Given an object that holds expected string based date and time values
	 * based on the utc time zone, this method will replace each date and time
	 * value with a consistently formatted system based timezone value of
	 * that date and time.
	 *
	 * @static
	 * @param {any} data
	 * The data to transform.
	 * @returns {any}
	 * A transformed business logic equal object holding formatted values
	 * expected by the system.
	 * @memberof ApiHelper
	 */
	public static transformBusinessLogicData(
		data: any): any
	{
		if (AnyHelper.isNull(data))
		{
			return null;
		}

		if (data instanceof Blob)
		{
			return data;
		}

		let stringifiedData: string =
			JSON.stringify(data);

		stringifiedData =
			stringifiedData
				.replaceAll(
					this.fullIsoExpression,
					this.replaceWithTimeZoneUtc.bind(this));

		return JSON.parse(
			stringifiedData);
	}

	/**
	 * Given a string value that represents a date and time object, this will
	 * transform and return the system time zone based value of that utc
	 * string.
	 *
	 * @static
	 * @param {string} value
	 * The string value to transform.
	 * @returns {string}
	 * A transformed business logic equal date time of the sent string based on
	 * the system time zone.
	 * @memberof ApiHelper
	 */
	private static replaceWithTimeZoneUtc(
		value: string): string
	{
		const convertedDateIso: string =
			DateHelper
				.fromUtcIso(
					value)
				.setZone(
					Settings.defaultZone)
				.toISO();

		return `${convertedDateIso}`;
	}
}