/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ChangeDetectorRef,
	Component,
} from '@angular/core';
import {
	DisplayComponentDefinitionApiService
} from '@api/services/display-components/display-component-definition.api.service';
import {
	DisplayComponentTypeApiService
} from '@api/services/display-components/display-component-type.api.service';
import {
	PowerBiWizardStepDirective
} from '@bi/directives/power-bi-wizard-step.directive';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	ReportConstants
} from '@shared/constants/report.constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IPowerBiReport
} from '@shared/interfaces/reports/power-bi/power-bi-report.interface';
import {
	PowerBiApiService
} from '@shared/services/power-bi-api.service';
import {
	PowerBiService
} from '@shared/services/power-bi.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-select-existing-power-bi-report',
	templateUrl: './select-existing-power-bi-report.component.html',
	styleUrls: [
		'./select-existing-power-bi-report.component.scss'
	]
})

/**
 * A component representing a select existing power bi report wizard step.
 *
 * @export
 * @class SelectExistingPowerBiReportComponent
 * @extends {PowerBiWizardStepDirective<IPowerBiReport>}
 * @implements {IDynamicComponent<DynamicWizardComponent, any>}
 */
export class SelectExistingPowerBiReportComponent
	extends PowerBiWizardStepDirective<IPowerBiReport>
	implements IDynamicComponent<DynamicWizardComponent, any>
{
	/**
	 * Creates an instance of the select existing power bi report component.
	 *
	 * @param {PowerBiService} powerBiService
	 * The power bi display service used for external power bi reports.
	 * @param {PowerBiApiService} powerBiApiService
	 * The power bi api service used for external power bi reports.
	 * @param {DisplayComponentDefinitionApiService}
	 * displayComponentDefinitionApiService
	 * The display component definition api service used in this component.
	 * @param {DisplayComponentTypeApiService}
	 * displayComponentTypeApiService
	 * The display component type api service used in this component.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 * @param {ChangeDetectorRef} changeDetectorReference
	 * The change detector used in this component.
	 * @memberof SelectExistingPowerBiReportComponent
	 */
	public constructor(
		public powerBiService: PowerBiService,
		public powerBiApiService: PowerBiApiService,
		public displayComponentDefinitionApiService:
			DisplayComponentDefinitionApiService,
		public displayComponentTypeApiService:
			DisplayComponentTypeApiService,
		public siteLayoutService: SiteLayoutService,
		public changeDetectorReference: ChangeDetectorRef)
	{
		super(
			powerBiService,
			powerBiApiService,
			displayComponentDefinitionApiService,
			displayComponentTypeApiService,
			siteLayoutService);
	}

	/**
	 * Gets or sets the value of whether or not the currently selected report
	 * should be displayed.
	 *
	 * @type {boolean}
	 * @memberof SelectExistingPowerBiReportComponent
	 */
	public displayReport: boolean = false;

	/**
	 * Gets or sets the value of whether or not the preview report button
	 * should be shown.
	 *
	 * @type {boolean}
	 * @memberof SelectExistingPowerBiReportComponent
	 */
	public displayReportPreview: boolean = false;

	/**
	 * Gets or sets the lookup type used when gathering available values of
	 * the api data type.
	 *
	 * @type {string}
	 * @memberof SelectExistingPowerBiReportComponent
	 */
	public lookupType: string = ReportConstants.powerBiObjectTypes.report;

	/**
	 * Gets the constant value for an existing report lookup.
	 *
	 * @type {string}
	 * @memberof SelectExistingPowerBiReportComponent
	 */
	private readonly existingOption: string = 'Existing';

	/**
	 * Gets the constant value for a new report lookup.
	 *
	 * @type {string}
	 * @memberof SelectExistingPowerBiReportComponent
	 */
	private readonly newOption: string = 'New';

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout holding available reports
	 * for selection.
	 *
	 * @async
	 * @memberof SelectExistingPowerBiReportComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.reportLocation',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Source',
						options: [
							<IDropdownOption>
							{
								label: this.newOption,
								value: this.newOption
							},
							<IDropdownOption>
							{
								label: this.existingOption,
								value: this.existingOption
							}
						],
						placeholder: AppConstants.placeholders.selectAnOption,
						required: true,
						change:
							async(_field: FormlyFieldConfig) =>
							{
								await this.resetReport();
							}
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.report',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Existing Report',
						tooltip: 'The existing report to clone.',
						options: this.groupedSelectionOptions,
						group: true,
						showClear: true,
						placeholder: AppConstants.placeholders.selectAnOption,
						required: true,
						hidden: true,
						filter: true,
						change:
							async(field: FormlyFieldConfig) =>
							{
								await this.handleSelectionChange(field);
							}
					},
					expressions: {
						hide: 'model.data?.reportLocation !== \'Existing\''
					}
				}
			];

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the preview report click event.
	 * This will display the external report component with the currently
	 * set report context.
	 *
	 * @memberof SelectExistingPowerBiReportComponent
	 */
	public previewReport(): void
	{
		this.displayReport = !this.displayReport;
	}

	/**
	 * Handles the item selection change event.
	 * This will store verify the selection is accurate and call the
	 * item selected business logic.
	 *
	 * @async
	 * @memberof SelectExistingPowerBiReportComponent
	 */
	private async handleSelectionChange(
		field: FormlyFieldConfig): Promise<void>
	{
		let shouldDisplayReportPreview: boolean = false;
		if (!AnyHelper.isNull(field.formControl.value)
			&& this.selectedItem !==
				field.formControl.value)
		{
			this.selectedItem = field.formControl.value;
			shouldDisplayReportPreview = true;
			await this.itemSelected();
		}
		else
		{
			await this.resetReport();
		}

		this.displayReportPreview = shouldDisplayReportPreview;
		this.changeDetectorReference.detectChanges();
	}

	/**
	 * Resets the previewed report to not be visible.
	 *
	 * @private
	 * @async
	 * @returns {Promise<void>}
	 * @memberof SelectExistingPowerBiReportComponent
	 */
	private async resetReport(): Promise<void>
	{
		this.selectedItem = null;
		this.displayReport = false;
		this.reportContext = null;
	}

	/**
	 * This will store this value in the current step and display the selected
	 * report.
	 *
	 * @async
	 * @memberof SelectExistingPowerBiReportComponent
	 */
	private async itemSelected(): Promise<void>
	{
		setTimeout(
			() =>
			{
				this.displayReport = false;
				this.reportContext = null;
			});

		const reportToClone: IPowerBiReport =
			<IPowerBiReport><unknown>this.selectedItem;
		await this.createReportDefinition(
			reportToClone);
	}
}