/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	ClaimsService
} from '@claims/services/claims.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	StatusReasonsDirective
} from '@insurance/directives/status-reasons.directive';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'manage-additional-payees-claim',
	templateUrl: './manage-additional-payees-claim.component.html'
})

/**
 * A component representing a wizard step for managing additional
 * payees on a claim payment.
 *
 * @export
 * @class ManageAdditionalPayeesClaimComponent
 * @extends {StatusReasonsDirective}
 */
export class ManageAdditionalPayeesClaimComponent
	extends StatusReasonsDirective
{
	/**
	 * Creates an instance of an manage additional payees
	 * claim component.
	 *
	 * @param {ClaimsService} claimsService
	 * The claims service for this component.
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {InsuranceService} insuranceService
	 * The insurance service used to lookup insurance modules upon creation.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	public constructor(
		public claimsService: ClaimsService,
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public insuranceService: InsuranceService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService
	)
	{
		super(
			router,
			activityService,
			moduleService,
			entityService,
			insuranceService,
			entityTypeApiService,
			entityInstanceApiService,
			sessionService);
	}

	/**
	 * Gets or sets the claim payment id.
	 *
	 * @type {number}
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private claimPaymentId: number;

	/**
	 * Gets or sets the claim payment instance data.
	 *
	 * @type {IEntityInstance}
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private claimPayment: IEntityInstance;

	/**
	 * Gets or sets the claims.
	 *
	 * @type {IEntityInstanceDto[]}
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private claims: IEntityInstanceDto[];

	/**
	 * Gets or sets the claim instance data.
	 *
	 * @type {IEntityInstance}
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private claim: IEntityInstance;

	/**
	 * Gets or sets the available payees.
	 *
	 * @type {any[]}
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private availablePayees: any[];

	/**
	 * Gets or sets the selected payees.
	 *
	 * @type {any[]}
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private selectedPayees: any[];

	/**
	 * Gets or sets the selected payees resource identifiers.
	 *
	 * @type {string[]}
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private selectedPayeesResourceIdentifiers: string[];

	/**
	 * Gets or sets the list of adjusting companies.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private adjustingCompanies: IEntityInstance[] = [];

	/**
	 * Sets the adjuster readonly string.
	 *
	 * @type {string}
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private readonly adjusterType: string = 'Adjuster';

	/**
	 * Sets the vendor readonly string.
	 *
	 * @type {string}
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private readonly vendorType: string = 'Vendor';

	/**
	 * Sets the reporter readonly string.
	 *
	 * @type {string}
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private readonly reporterType: string = 'Reporter';

	/**
	 * Allows additional actions following the shared status reasons directive
	 * on init.
	 *
	 * @async
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claimPayments;

		this.claimPaymentId =
			this.context.source.activeMenuItem
				.currentData.data.id;

		this.claimPayment =
			await this.entityInstanceApiService.get(this.claimPaymentId);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claimPayments;

		this.claims =
			await this.entityInstanceApiService.getParents(
				this.claimPaymentId,
				AppConstants.empty,
				AppConstants.empty,
				null,
				1,
				ClaimConstants.claimEntityTypeGroups.claims);

		this.claim = this.claims[0];

		this.adjustingCompanies =
			await this.claimsService.getClaimAdjusterOrganizations(
				this.claim.id);

		this.selectedPayees =
			await this.getSelectedPayees(
				this.claimPayment,
				this.claim);

		this.availablePayees =
			await this.getAvailablePayees(
				this.claim,
				this.claimPayment);

		this.context.source.addOrUpdateStepData(
			{
				availableReasons: this.availablePayees,
				selectedReasons: this.selectedPayees,
				comments: AppConstants.empty,
				displayCommentBox: false
			});

		this.context.source.addToNext(this.confirmPayees.bind(this));
	}

	/**
	 * This will send the confirm payees event and navigate to
	 * the claim payment.
	 *
	 * @async
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	public async confirmPayees(): Promise<void>
	{
		await this.saveAdditionalPayees();
		await this.navigateToClaimPayment(
			this.claimPaymentId,
			ClaimConstants.claimEntityTypeGroups.claimPayments
		);
	}

	/**
	 * Executes the manage additional payees process by executing the
	 * ManageAdditionalPayees workflow action to update the claim payment
	 * with the collected data from the Manage Additional Payees Wizard.
	 *
	 * @async
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	public async saveAdditionalPayees(): Promise<void>
	{
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		const queryString: string =
			this.entityInstanceApiService.formUrlParam(
				AppConstants.empty,
				{
					additionalPayeesStringObject:
						JSON.stringify(currentData.selectedReasons)
				});

		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claimPayments;

		return this.activityService.handleActivity(
			new Activity(
				this.entityInstanceApiService.executeAction(
					this.claimPaymentId,
					ClaimConstants.workflowActions.manageAdditionalPayees,
					null,
					queryString),
				'<strong>Modifying Additional Payees</strong>',
				'<strong>Additional Payees Modified</strong>',
				'Additional payee changes have been saved.',
				'Additional payee changes have not been saved.'));
	}

	/**
	 * This will navigate to the claim payment layout using the
	 * claim payment id provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The claim payment entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private async navigateToClaimPayment(
		entityId: number,
		group: string): Promise<void>
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Gets the available additional payees.
	 *
	 * @async
	 * @param {IEntityInstance} claim
	 * The claim instance.
	 * @param {IEntityInstance} claimPayment
	 * The claim payment instance.
	 * @returns {Promise<any>}
	 * The list of available payees for a claim.
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private async getAvailablePayees(
		claim: IEntityInstance,
		claimPayment: IEntityInstance): Promise<any[]>
	{
		let availablePayees: any = claim.data.involvedParties
			.filter(
				(party: any) =>
					party.type !== this.reporterType
						&& !this.selectedPayeesResourceIdentifiers.includes(
							party.resourceIdentifier)
						&& party.resourceIdentifier !==
							claimPayment.data.payee.id);

		availablePayees =
			await this.formatPayees(availablePayees);

		return availablePayees;
	}

	/**
	 * Gets the selected additional payees.
	 *
	 * @async
	 * @param {IEntityInstance} claimPayment
	 * The claim payment instance.
	 * @param {IEntityInstance} claim
	 * The claim instance.
	 * @returns {Promise<any>}
	 * The list of all selected additional payees for a claim payment.
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private async getSelectedPayees(
		claimPayment: IEntityInstance,
		claim: IEntityInstance): Promise<any[]>
	{
		let selectedPayees: any[] = [];
		const selectedPayeesResourceIdentifiers: any[] = [];

		const additionalPayees =
			claimPayment.data.additionalPayees;

		for await (const payee of additionalPayees)
		{
			const payeeData = claim.data.involvedParties.find(
				(party: any) =>
					party.resourceIdentifier === payee.id);

			selectedPayees.push(payeeData);
			selectedPayeesResourceIdentifiers.push(payee.id);
		}

		this.selectedPayeesResourceIdentifiers =
			selectedPayeesResourceIdentifiers;

		selectedPayees =
			await this.formatPayees(selectedPayees);

		return selectedPayees;
	}

	/**
	 * Formats payees data.
	 *
	 * @async
	 * @param {any[]} payees
	 * The array of payees.
	 * @returns {Promise<any[]>}
	 * The list of formatted payees.
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private async formatPayees(
		payees: any[]): Promise<any[]>
	{
		const formattedPayees: any[] = [];

		for await (const payee of payees)
		{
			switch (payee.type)
			{
				case this.vendorType:
				{
					formattedPayees.push(
						await this.formatVendor(payee));
					break;
				}
				case this.adjusterType:
				{
					formattedPayees.push(
						await this.formatAdjuster(payee));
					break;
				}
				default:
					formattedPayees.push(
						await this.formatPayee(payee));
					break;
			}
		}

		return formattedPayees;
	}
	/**
	 * Formats payee data.
	 *
	 * @async
	 * @param {any} payee
	 * The payee data.
	 * @returns {Promise<any>}
	 * The formatted payee data.
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private async formatPayee(
		payee: any): Promise<any>
	{
		const formattedName: string =
			StringHelper.toNameString(
				payee?.name?.firstName,
				payee?.name?.lastName);

		const payeeAddress: any =
			payee?.addresses[0];

		return {
			name: StringHelper.beforeCapitalSpaces(payee.type),
			description: formattedName,
			resourceIdentifier: payee.resourceIdentifier,
			payeeData: {
				id: payee.resourceIdentifier,
				name: formattedName,
				type: payee.type,
				address: {
					type: payeeAddress?.type,
					address: payeeAddress?.address,
					city: payeeAddress?.city,
					state: payeeAddress?.state,
					principalSubdivision: payeeAddress?.principalSubdivision,
					postalCode: payeeAddress?.postalCode,
					country: payeeAddress?.country
				}
			}
		};
	}

	/**
	 * Formats vendor data.
	 *
	 * @async
	 * @param {any} vendor
	 * The vendor data.
	 * @returns {Promise<any>}
	 * The formatted vendor data.
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private async formatVendor(
		vendor: any): Promise<any>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.serviceProviderEntityTypeGroups.vendorCompany;

		const vendorInstance: IEntityInstanceDto =
			await this.entityInstanceApiService.get(
				+vendor.vendorOrganizationId);

		const formattedDescription: string =
			this.claimsService.formatClaimOrganizationData(
				vendorInstance.data?.vendorType,
				vendorInstance.data?.name?.legalName,
				AppConstants.empty,
				AppConstants.empty);

		const vendorAddress: any =
			vendorInstance.data?.addresses[0];

		return {
			name: this.vendorType,
			description: formattedDescription,
			resourceIdentifier: vendor.resourceIdentifier,
			payeeData: {
				id: vendor.resourceIdentifier,
				name: vendorInstance.data?.name?.legalName,
				type: this.vendorType,
				address: {
					type: vendorAddress?.type,
					address: vendorAddress?.address,
					city: vendorAddress?.city,
					state: vendorAddress?.state,
					principalSubdivision: vendorAddress?.principalSubdivision,
					postalCode: vendorAddress?.postalCode,
					country: vendorAddress?.country
				}
			}
		};
	}

	/**
	 * Formats adjuster data.
	 *
	 * @async
	 * @param {any} adjuster
	 * The adjuster data.
	 * @returns {Promise<any>}
	 * The formatted adjuster data.
	 * @memberof ManageAdditionalPayeesClaimComponent
	 */
	private async formatAdjuster(
		adjuster: any): Promise<any>
	{
		const adjusterInstance: IEntityInstanceDto =
			this.adjustingCompanies.find(
				(organization: any) =>
					organization.id === +adjuster.adjustingOrganizationId);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.users;

		const userInstance: IEntityInstanceDto =
			await this.entityInstanceApiService.get(
				+adjuster.adjustingOrganizationUserId);

		const formattedDescription: string =
			this.claimsService.formatClaimOrganizationData(
				adjusterInstance.data?.adjustingType
					?? adjusterInstance.data?.tpaType,
				adjusterInstance.data?.name?.legalName,
				userInstance.data?.firstName,
				userInstance.data?.lastName);

		const adjusterAddress: any =
			adjusterInstance.data?.addresses[0];

		return {
			name: this.adjusterType,
			description: formattedDescription,
			resourceIdentifier: adjuster.resourceIdentifier,
			payeeData: {
				id: adjuster.resourceIdentifier,
				name: adjusterInstance.data?.name?.legalName,
				type: this.adjusterType,
				address: {
					type: adjusterAddress?.type,
					address: adjusterAddress?.address,
					city: adjusterAddress?.city,
					state: adjusterAddress?.state,
					principalSubdivision: adjusterAddress?.principalSubdivision,
					postalCode: adjusterAddress?.postalCode,
					country: adjusterAddress?.country
				}
			}
		};
	}
}