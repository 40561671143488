<div #TableContainer
	class="shared-common-table-container"
	clickOutside
	[contentDisplayed]="sidebarVisible"
	[ignoreAppendedBodyPanelClicks]="true"
	(clickOutside)="displayTable()">
	<div #TableTitle
		class="table-title panel-sub-title ui-g-12 no-padding"
		[ngClass]="{
			'loading-border': initialLoadComplete === false
		}">
		<div class="ui-g-8 no-padding table-title-text ellipsis-text"
			[ngClass]="{
				'hidden-title': tableDefinitions.hideTableTitle === true
			}">
			{{tableDefinitions.tableTitle}}
		</div>
		<div class="ui-g-4 no-padding table-actions-container"
			*ngIf="tableDefinitions.actions != null"
			[ngClass]="{
				'contains-filter': tableDefinitions.actions.filter?.quickFilters?.length > 0,
			}">
			<span class="table-action-container float-right"
				*ngIf="tableDefinitions.actions.tableLevelEllipsis != null">
				<a id="ellipsisTableAction"
					class="table-action-container float-right ">
					<operation-button-menu
						useEllipsis="true"
						[operationGroup]="tableDefinitions.actions.tableLevelEllipsis"
						[invertedColors]="true"
						[smallEllipsis]="true">
					</operation-button-menu>
				</a>
			</span>
			<span class="table-action-container float-right"
				*ngIf="tableDefinitions.actions.filter?.quickFilters?.length > 0"
				[ngClass]="{
					'active-icon-color': filterVisible === true,
					'disabled-link': initialLoadComplete === false
				}">
				<app-quick-filters
					[filters]="tableDefinitions.actions.filter.quickFilters"
					[selectedFilterValue]="tableDefinitions.actions.filter.selectedFilterValue"
					(quickFilterSelectedEvent)="filterCriteriaChanged($event)"
					(quickFilterDisplayedEvent)="quickFilterDisplayed($event)">
				</app-quick-filters>
			</span>
			<span class="table-action-container float-right"
				*ngIf="tableDefinitions.hideSettings !== true"
				[ngClass]="{
					'border-selected-theme': settingsVisible === true,
					'active-icon-color': settingsVisible === true,
					'disabled-link': initialLoadComplete === false
				}"
				(click)="toggleSettingsDisplay()">
				<span>
					<a id="clickableSettings"
						class="table-action">
						<i class="fa fa-gear">
						</i>
					</a>
				</span>
			</span>
			<span class="table-action-container float-right"
				*ngIf="tableDefinitions.actions.create && !field?.props?.disabled"
				[ngClass]="{
					'border-selected-theme': displayMode === 'Create',
					'active-icon-color': displayMode === 'Create',
					'disabled-link': initialLoadComplete === false
				}"
				(click)="toggleCreateDisplay()">
				<a id="createAction"
					class="table-action">
					<i class="fa fa-plus-circle">
					</i>
				</a>
			</span>
		</div>
	</div>

	<div class="common-table-sidebar-container no-scrollbar"
		*ngIf="initialLoadComplete === true">
		<div class="table-drawer-container"
			#TableDrawer>
			<p-sidebar
				[(visible)]="sidebarVisible"
				position="right"
				transitionOptions="200ms cubic-bezier(0, 0, 0.2, 1)"
				[showCloseIcon]="false">
				<ng-template pTemplate="header">
					<div class="sidebar-title panel-sub-title">
						{{expandTitle}}
					</div>
					<div class="sidebar-close-container float-right">
						<div class="sidebar-close-icon-container">
							<a class="sidebar-close cursor-pointer"
								(click)="displayTable()">
								<i class="sidebar-close-icon pi pi-times">
								</i>
							</a>
						</div>
					</div>
				</ng-template>
				<ng-template pTemplate="content">
					<p-scrollPanel
						styleClass="expander-scroll">
						<div #ScrollContainer
							class="ui-g-12 sidebar-container {{siteLayoutService.contentCssClass}}">
							<div *ngIf="dynamicComponent != null">
								<app-dynamic-component
									[context]="pageContext"
									[displayComponent]="dynamicComponent">
								</app-dynamic-component>
							</div>
							<div *ngIf="dynamicComponent == null">
								<div class= "ui-g-6 no-padding"
									[ngClass]="{
										'ui-g-12': formlyDefinitions.additionalLayout == null
									}">
									<app-dynamic-formly
										[dataSet]="selectedItem"
										[layoutSchema]="formlyDefinitions.layout"
										[context]="pageContext"
										(validityChanged)="validExpandComponentChanged($event)">
									</app-dynamic-formly>
								</div>
								<div *ngIf="formlyDefinitions.additionalLayout != null"
									class="ui-g-6 no-padding">
									<app-dynamic-formly
										[dataSet]="selectedItem"
										[layoutSchema]="formlyDefinitions.additionalLayout"
										[context]="pageContext"
										(validityChanged)="validExpandComponentChanged($event)">
									</app-dynamic-formly>
								</div>
							</div>
						</div>
					</p-scrollPanel>
				</ng-template>
				<ng-template pTemplate="footer">
					<div class="panel-button-set">
						<span class="panel-actions-container float-right"
						[ngClass]="{
							'cancel-only': expandActions?.length === 0
						}"
							*ngIf="displayingExpandActions"
							[@contentAnimation]="state">
							<a class="text-link theme-color secondary-action-button"
								(click)="displayTable()">
								Cancel
							</a>
							<operation-button-bar
								[initialModel]="expandActions"
								[pageContext]="pageContext"
								fixedOverlayLocation="TopLeft">
							</operation-button-bar>
						</span>
					</div>
				</ng-template>
			</p-sidebar>
		</div>

		<div cdkVirtualScrollingElement
			class="table-viewport"
			[style.height]="tableHeight"
			[ngClass]="{
				'no-scrollbar': sidebarVisible === true,
				'no-scroll': sidebarVisible === true,
				'table-mask': initialLoadComplete === false
					|| sidebarVisible === true
			}">

			<table class="sticky-header">
				<caption class="visually-hidden">
					A set of table headers displaying {{tableDefinitions.tableTitle}} data.
				</caption>
				<thead>
					<tr>
						<ng-container
							*ngFor="let column of visibleColumns">
							<th scope="col"
								[style.width]="column.width"
								tooltipPosition="top"
								[pTooltip]="column.tooltip ?? column.columnHeader">
								<div class="ellipsis-text column-display">
									{{column.columnHeader}}
								</div>
							</th>
						</ng-container>
					</tr>
				</thead>
			</table>

			<div class="shared-table-container">
				<cdk-virtual-scroll-viewport
					class="table-body-viewport"
					[itemSize]="rowHeight"
					(scrolledIndexChange)="checkIfLastItem($event)">
					<table>
						<caption class="visually-hidden">
							A table displaying {{tableDefinitions.tableTitle}} row data.
						</caption>
						<thead class="visually-hidden">
							<tr>
								<ng-container *ngFor="let column of visibleColumns">
									<th scope="col"
										[style.width]="column.width">
										<div class="ellipsis-text column-display">
											{{column.columnHeader}}
										</div>
									</th>
								</ng-container>
							</tr>
						</thead>
						<tbody>
							<ng-container *cdkVirtualFor="let item of virtualData">
								<tr [ngClass]="{
										'cursor-pointer': rowActionsExist === true && navigationHighlighting === true,
										'theme-hover': rowActionsExist === true && navigationHighlighting === true,
										'theme-background': isItemSelected(item)
									}"
									(click)="selectItem(item, 'View', $event)">
									<ng-container *ngFor="let column of visibleColumns">
										<td [style.width]="column.width">
											<div [ngSwitch]="true">
												<div *ngSwitchCase="loadingTableData === true || loadingNextDataset === true">
													<p-skeleton styleClass="column-load-display"></p-skeleton>
												</div>
												<div class="ellipsis-text column-display"
													*ngSwitchCase="(loadingTableData === false && loadingNextDataset === false)
														&& (column.dataFormatType === 'Date' || column.dataFormatType === 'ShortDate')">
													{{getTableColumnData(item, column.dataKey) | dateTimeFromIso | dateTimeToLocal | dateTimeToFormat:'D'}}
												</div>
												<div class="ellipsis-text column-display"
													*ngSwitchCase="(loadingTableData === false && loadingNextDataset === false)
														&& (column.dataFormatType === 'DateTime' || column.dataFormatType === 'LongDate')">
													{{getTableColumnData(item, column.dataKey) | dateTimeFromIso | dateTimeToLocal | dateTimeToFormat:'D h:mm a'}}
												</div>
												<div class="ellipsis-text column-display"
													*ngSwitchCase="(loadingTableData === false && loadingNextDataset === false)
														&& column.dataFormatType === 'Currency'">
													{{getTableColumnData(item, column.dataKey) | currency : 'USD': 'symbol' : column.formattedRoundingPrecision}}
												</div>
												<div class="ellipsis-text column-display"
													*ngSwitchCase="(loadingTableData === false && loadingNextDataset === false)
														&& column.dataFormatType === 'Decimal'">
													{{getTableColumnData(item, column.dataKey) | number : column.formattedRoundingPrecision}}
												</div>
												<div class="ellipsis-text column-display"
													*ngSwitchCase="(loadingTableData === false && loadingNextDataset === false)
														&& column.dataFormatType === 'Percent'">
													{{getTableColumnData(item, column.dataKey) | percent : column.formattedRoundingPrecision}}
												</div>
												<div class="ellipsis-text column-display"
													*ngSwitchCase="(loadingTableData === false && loadingNextDataset === false)
														&& column.dataFormatType === 'Icon'">
													<app-common-icon-tooltip
														[content]="getTableColumnData(item, column.dataKey + 'Tooltip')"
														iconClass="icon-data-column {{getTableColumnData(item, column.dataKey)}}">
													</app-common-icon-tooltip>
												</div>
												<div *ngSwitchCase="loadingTableData === false
													&& loadingNextDataset === false
													&& column.displayOrder === actionColumnDisplayOrder
													&& (tableDefinitions.actions?.ellipsis != null
														|| tableDefinitions.actions?.update != null
														|| tableDefinitions.actions?.delete != null
														|| tableDefinitions.actions?.updateIndex != null)">
													<div class="row-actions-container">
														<ng-template
															[ngIf]="tableDefinitions.actions?.updateIndex != null">
																<div class="row-action-container index-action-container"
																	[ngClass]="{
																		'double-index-action-container':
																			item?.order !== virtualData[0]?.order
																				&& item?.order !== virtualData[virtualData.length - 1]?.order
																	}">
																	<a id="updateIndexUp"
																		*ngIf="item?.order !== virtualData[0]?.order"
																		(click)="updateIndex(item, 'Up', $event)">
																		<i class="row-action pi pi-chevron-up">
																		</i>
																	</a>
																	<a id="updateIndexDown"
																		*ngIf="item?.order !== virtualData[virtualData.length - 1]?.order"
																		(click)="updateIndex(item, 'Down', $event)">
																		<i class="row-action pi pi-chevron-down">
																		</i>
																	</a>
																</div>
														</ng-template>
														<ng-template
															[ngIf]="tableDefinitions.actions.update != null">
																<a id="editAction"
																	class="row-action-container"
																	(click)="selectItem(item, 'Update', $event)">
																	<i class="row-action fa fa-pencil">
																	</i>
																</a>
														</ng-template>
														<ng-template
															[ngIf]="tableDefinitions.actions.delete != null">
																<a id="deleteAction"
																	class="row-action-container"
																	(click)="selectItem(item, 'Delete', $event)">
																	<i class="row-action fa fa-trash">
																	</i>
																</a>
														</ng-template>
														<ng-template
															[ngIf]="tableDefinitions.actions.rowLevelEllipsis != null">
																<a id="ellipsisRowAction"
																	class="row-action-container">
																	<operation-button-menu
																		[useEllipsis]="true"
																		[operationGroup]="tableDefinitions.actions.rowLevelEllipsis"
																		[invertedColors]="true"
																		[smallEllipsis]="true">
																	</operation-button-menu>
																</a>
														</ng-template>
													</div>
												</div>
												<div class="ellipsis-text column-display"
													tooltipPosition="top"
													[pTooltip]="getTableColumnData(item, column.dataKey, column.dataFunction)"
													*ngSwitchDefault>
													{{getTableColumnData(item, column.dataKey, column.dataFunction)}}
												</div>
											</div>
										</td>
									</ng-container>
								</tr>
							</ng-container>
						</tbody>
					</table>
				</cdk-virtual-scroll-viewport>
			</div>
		</div>
	</div>

	<div #TableSummary
		class="table-summary">
		<span class="table-summary-text">
			<div [ngSwitch]="true">
				<div *ngSwitchCase="tableFullyLoaded === true
					&& displayKeywordMessage === true">
					This entity type is not searchable, please add summary data to the definition.
				</div>
				<div *ngSwitchCase="tableFullyLoaded === true
					&& tableData.length > 0">
					Displaying {{tableData.length > 1 ? 'all' : ''}} {{tableData.length}} {{tableData.length > 1 ? 'results' : 'result'}}.
				</div>
				<div *ngSwitchCase="tableFullyLoaded === true
					&& tableData.length === 0
					&& displayKeywordMessage === false">
					No query results found.
				</div>
				<div *ngSwitchCase="initialLoadComplete === true
					&& tableFullyLoaded === false
					&& tableData.length > 0">
					<ng-template [ngIf]="tableDefinitions.actions?.filter?.considerFilteringResults == null
						|| tableData.length < filterResultsSuggestionCount"
						[ngIfElse]="FilterResultsSuggestion">
						Displaying top {{tableData.length}} {{tableData.length > 1 ? 'results' : 'result'}}.
						<a id="loadMoreLink"
							class="additional-results-link text-link"
							(click)="scrollTableToBottom()">
							Please scroll or click here for additional results.
						</a>
					</ng-template>
					<ng-template #FilterResultsSuggestion>
						We have found more than {{filterResultsSuggestionCount}} results.
						<a id="filterResultsLink"
							class="additional-results-link text-link"
							(click)="tableDefinitions.actions.filter.considerFilteringResults()">
							Consider filtering your search.
						</a>
					</ng-template>
				</div>
				<div class="ellipsis-text"
					*ngSwitchDefault>
					<span>
						Loading query results
					</span>
					<span class="theme-color fa fa-fw fa-spin fa-spinner">
					</span>
				</div>
			</div>
		</span>
	</div>
</div>