/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ChangeDetectorRef,
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	IInsuranceEntityTypes
} from '@insurance/interfaces/insurance-entity-types.interface';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	ActivityService
} from '@shared/services/activity.service';

/* eslint-enable max-len */

@Component({
	selector: 'transaction-endorse-issue-summary',
	templateUrl: './transaction-endorse-issue-summary.component.html'
})

/**
 * A component representing a wizard step for issuing an endorsement on
 * a policy transaction.
 *
 * @export
 * @class TransactionEndorseIssueSummaryComponent
 */
export class TransactionEndorseIssueSummaryComponent implements OnInit
{
	/**
	 * Creates a new instance of the transaction endorse issue summary
	 * component.
	 *
	 * @param {Router} router
	 * The router used for navigation.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {InsuranceService} insuranceService
	 * The insruance service used in this component.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {ChangeDetectorRef} changeDetectorReference
	 * The change detector reference used to update the view when differences
	 * are displayed and loaded.
	 * @memberof TransactionEndorseIssueDifferencesComponent
	 */
	 public constructor(
		public router: Router,
		public activityService: ActivityService,
		public insuranceService: InsuranceService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public changeDetectorReference: ChangeDetectorRef)
	{
	}

	/**
	 * Gets the workflow action being executed against the current
	 * wizard step entity instance.
	 *
	 * @type {string}
	 * @memberof TransactionEndorseIssueSummaryComponent
	 */
	public static readonly workflowActionName: string =
		'TransactionEndorseIssue';

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<DynamicWizardComponent, IWizardContext>}
	 * @memberof TransactionEndorseIssueSummaryComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the existing transaction instance that is being endorsed.
	 *
	 * @type {IEntityInstance}
	 * @memberof TransactionEndorseIssueSummaryComponent
	 */
	public existingTransactionInstance: IEntityInstance;

	/**
	 * Gets or sets the pending endorse transaction instance.
	 *
	 * @type {IEntityInstance}
	 * @memberof TransactionEndorseIssueSummaryComponent
	 */
	public endorseTransactionInstance: IEntityInstance;

	/**
	 * Gets or sets the pending endorse transaction policy term parent instance.
	 *
	 * @type {IEntityInstance}
	 * @memberof TransactionEndorseIssueSummaryComponent
	 */
	public policyTermInstance: IEntityInstance;

	/**
	 * Gets or sets the set of insurance entity types.
	 *
	 * @type {IInsuranceEntityTypes}
	 * @memberof TransactionEndorseIssueSummaryComponent
	 */
	public insuranceEntityTypes: IInsuranceEntityTypes;

	/**
	 * Gets the dashboard that will be displayed in this wizard step.
	 *
	 * @type {string}
	 * @memberof TransactionEndorseIssueSummaryComponent
	 */
	public readonly dashboardDisplayComponentInstanceName: string =
		'TransactionEndorseSummaryDashboard';

	/**
	 * Implements the on initialization interface.
	 * This will set up the summary display and set the next action.
	 *
	 * @memberof TransactionEndorseIssueSummaryComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.context.source.wizardStepLoading = true;

		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;
		this.insuranceEntityTypes =
			await this.insuranceService.populateInsuranceEntityTypes();

		this.entityInstanceApiService.entityInstanceTypeGroup =
			currentData.entityTypeGroup;
		this.endorseTransactionInstance =
			await this.entityInstanceApiService
				.get(currentData.entityId);
		this.existingTransactionInstance =
			await this.entityInstanceApiService
				.get(currentData.basedOnEntityId);

		const endorseAccounting: any =
			this.endorseTransactionInstance.data.accounting;

		const policyTermParents: IEntityInstance[] =
			await this.entityInstanceApiService.getParents(
				this.existingTransactionInstance.id,
				AppConstants.empty,
				AppConstants.empty,
				0,
				1,
				this.insuranceEntityTypes.policyTermEntityType.group);

		this.policyTermInstance = policyTermParents[0];
		this.entityInstanceApiService.entityInstanceTypeGroup =
			this.insuranceEntityTypes.policyTermEntityType.group;
		const policyTermTransactions: IEntityInstance[] =
			await this.entityInstanceApiService.getChildren(
				this.policyTermInstance.id,
				`${AppConstants.commonProperties.status} eq `
					+ `"${InsuranceConstants.transactionStatusTypes.issued}"`,
				AppConstants.empty,
				0,
				AppConstants.dataLimits.large,
				currentData.entityTypeGroup);
		const policyTermLedgers: IEntityInstance[] =
			await this.entityInstanceApiService.getChildren(
				this.policyTermInstance.id,
				AppConstants.empty,
				`${AppConstants.commonProperties.id} `
					+ AppConstants.sortDirections.descending,
				0,
				1,
				this.insuranceEntityTypes.ledgerEntityType.group);

		const totalDirectWrittenPremium: number =
			policyTermTransactions.reduce(
				(accumulator: number,
					policyTermTransaction: IEntityInstance) =>
					accumulator +
						policyTermTransaction.data
							.accounting.directWrittenPremium,
				0);

		this.context.source.addOrUpdateStepData(
			{
				transactionEffectiveDate:
					this.endorseTransactionInstance.data.effectiveDate,
				premiumChange:
					endorseAccounting.directWrittenPremium,
				totalPremium:
					totalDirectWrittenPremium
						+ endorseAccounting.directWrittenPremium,
				remainingBalance:
					policyTermLedgers[0].data.balance
						+ endorseAccounting.directWrittenPremium
						+ endorseAccounting.directWrittenFees
						+ endorseAccounting.directWrittenTaxes
			});

		this.context.source.addToNext(this.issueEndorsement.bind(this));

		this.context.source.updateGuardComparisonData();
		this.context.source.validStepChanged(true);
		this.context.source.wizardStepLoading = false;

		setTimeout(
			() =>
			{
				this.changeDetectorReference.detectChanges();
			});
	}

	/**
	 * Issues a selected endorsement.
	 *
	 * @async
	 * @memberof TransactionEndorseIssueSummaryComponent
	 */
	public async issueEndorsement(): Promise<void>
	{
		await this.transactionEndorseIssue();
		this.navigateToPolicyTermDashboard();
	}

	/**
	 * Fires a change detection cycle allowing the form to broadcast changes
	 * to the listening component.
	 *
	 * @memberof TransactionEndorseIssueSummaryComponent
	 */
	public fireChanges(): void
	{
		this.changeDetectorReference.detectChanges();
	}

	/**
	 * Calls the fireChanges on a set time out
	 * to avoid any dashboard locked view on not detecting
	 * the loading false state upon changes.
	 *
	 * @memberof TransactionEndorseIssueSummaryComponent
	 */
	public customDashboardChanges()
	{
		setTimeout(
			() =>
			{
				this.fireChanges();
			},
			AppConstants.time.oneSecond);
	}

	/**
	 * Executes the transaction endorse process by executing the PolicyEndorse
	 * workflow action to update the associated Policy term transaction
	 * entity data with the collected data from the Endorse Wizard.
	 *
	 * @async
	 * @memberof TransactionEndorseIssueSummaryComponent
	 */
	private async transactionEndorseIssue(): Promise<void>
	{
		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		const entityType: IEntityType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.name} eq `
						+ `'${this.existingTransactionInstance
							.entityType}'`,
					AppConstants.empty);
		const displayName: string =
			new EntityType(entityType)
				.displayName;

		return this.activityService.handleActivity(
			new Activity(
				new Promise(
					async(resolve: any) =>
					{
						this.entityInstanceApiService.entityInstanceTypeGroup =
							entityType.group;
						await this.entityInstanceApiService
							.executeAction(
								this.endorseTransactionInstance.id,
								TransactionEndorseIssueSummaryComponent
									.workflowActionName,
								this.endorseTransactionInstance);

						resolve();
					}),
				'<strong>Issuing endorsement</strong>',
				'<strong>Endorsement issued</strong>',
				`${displayName} endorsement has been issued.`,
				`${displayName} endorsement has not been issued.`));
	}

	/**
	 * This will navigate to the parent policy term dashboard.
	 *
	 * @memberof TransactionEndorseIssueSummaryComponent
	 */
	private navigateToPolicyTermDashboard(): void
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${AppConstants.moduleNames.policy}/entities`,
				this.insuranceEntityTypes.policyTermEntityType.group,
				AppConstants.viewTypes.edit,
				this.policyTermInstance.id
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}
}