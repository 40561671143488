/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	UntypedFormControl
} from '@angular/forms';
import {
	Router
} from '@angular/router';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	ClaimsService
} from '@claims/services/claims.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	SharedTypeConstants
} from '@shared/constants/shared-type-constants';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	SessionService
} from '@shared/services/session.service';

@Component({
	selector: 'add-claim-payment',
	templateUrl: './add-claim-payment.component.html',
	styleUrls: []
})

/**
 * A component representing a wizard step for add claim payment.
 *
 * @export
 * @class AddClaimPaymentComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class AddClaimPaymentComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the add claim payment component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @param {ClaimsService} claimsService
	 * The claims service used in this component.
	 * @memberof AddClaimPaymentComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService,
		public claimsService: ClaimsService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof AddClaimPaymentComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof AddClaimPaymentComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the claim payment entity type.
	 *
	 * @type {IEntityType}
	 * @memberof AddClaimPaymentComponent
	 */
	public claimPaymentEntityType: IEntityType;

	/**
	 * Gets or sets the claim id.
	 *
	 * @type {number}
	 * @memberof AddClaimPaymentComponent
	 */
	private claimId: number;

	/**
	 * Gets or sets the claim instance data.
	 *
	 * @type {IEntityInstance}
	 * @memberof AddClaimPaymentComponent
	 */
	private claim: IEntityInstance;

	/**
	 * Gets or sets the claim payment id.
	 *
	 * @type {number}
	 * @memberof AddClaimPaymentComponent
	 */
	private claimPaymentId: number;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof AddClaimPaymentComponent
	 */
	private currentData: any;

	/**
	 * Gets or sets the list of adjusting companies.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof AddClaimPaymentComponent
	 */
	private adjustingCompanies: IEntityInstance[] = [];

	/**
	 * Gets or sets the list of vendor companies.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof AddClaimPaymentComponent
	 */
	private vendorCompanies: IEntityInstance[] = [];

	/**
	 * Sets the adjuster readonly string.
	 *
	 * @type {string}
	 * @memberof AddClaimPaymentComponent
	 */
	private readonly adjusterType: string = 'Adjuster';

	/**
	 * Sets the vendor readonly string.
	 *
	 * @type {string}
	 * @memberof AddClaimPaymentComponent
	 */
	private readonly vendorType: string = 'Vendor';

	/**
	 * Sets the claim payment pending status readonly string.
	 *
	 * @type {string}
	 * @memberof AddClaimPaymentComponent
	 */
	private readonly pendingStatus: string = 'Pending';

	/**
	 * Implements the on initialization interface.
	 *
	 * @memberof AddClaimPaymentComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData;

		this.claimId = this.currentData.data.id;

		this.claimPaymentEntityType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					'Group eq '
						+ `'${ClaimConstants.claimEntityTypeGroups
							.claimPayments}'`
						+ ' AND Name eq '
						+ `'${ClaimConstants.claimEntityTypes.claimPayment}'`,
					AppConstants.empty);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		this.claim =
			await this.entityInstanceApiService.get(this.claimId);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.serviceProviderEntityTypeGroups.adjustingCompany;

		this.adjustingCompanies =
			await this.claimsService.getClaimAdjusterOrganizations(
				this.claimId);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.serviceProviderEntityTypeGroups.vendorCompany;

		this.vendorCompanies =
			await ApiHelper.getFullDataSet(
				this.entityInstanceApiService,
				AppConstants.empty,
				AppConstants.empty);

		this.context.source.addToNext(this.create.bind(this));

		await this.performPostInitActions();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof AddClaimPaymentComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * This will send the claim payment create event and
	 * navigate to the new entity.
	 *
	 * @async
	 * @memberof AddClaimPaymentComponent
	 */
	public async create(): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claimPayments;

		const claimPaymentData: IEntityInstance =
			this.createClaimPaymentData(this.currentData.data);

		this.claimPaymentId =
			await this.activityService.handleActivity(
				new Activity(
					this.createEntity(
						this.claimPaymentEntityType,
						claimPaymentData,
						ClaimConstants.claimEntityTypeGroups.claims,
						this.claimId,
						{}),
					'<strong>Creating Claim Payment</strong>',
					'<strong>Claim Payment Created</strong>',
					'Claim Payment has been created.',
					'Claim Payment has not been created.'));

		await this.navigateToClaimPayment(
			this.claimPaymentId,
			ClaimConstants.claimEntityTypeGroups.claimPayments);
	}

	/**
	 * Validates the payee type.
	 *
	 * @return {boolean}
	 * The payee type validity
	 * @memberof AddClaimPaymentComponent
	 */
	public async validPayeeType(field: FormlyFieldConfig): Promise<boolean>
	{
		const payees: any[] =
			!AnyHelper.isNullOrEmpty(field.formControl.value)
				? this.claim.data.involvedParties.find(
					(party: any) =>
						party.type ===
							field.formControl.value)
				: [];

		if (AnyHelper.isNullOrEmpty(payees))
		{
			field.asyncValidators.payeeTypeValidator.message =
				'No payees exist for this payee type.';
		}

		return Promise.resolve(!AnyHelper.isNullOrEmpty(payees));
	}

	/**
	 * Creates the claim payment entity intance data based on the collected
	 * data. This data will be saved on the creation process.
	 *
	 * @param {any} currentData
	 * The claim payment wizard data.
	 * @memberof AddClaimPaymentComponent
	 */
	private createClaimPaymentData(
		currentData: any): IEntityInstance
	{
		const payeeData: any =
			this.claim.data.involvedParties.find(
				(party: any) =>
					party.resourceIdentifier ===
						currentData.payee.name);

		let payeeName: string;

		if (payeeData.type === this.vendorType)
		{
			const vendorInformation =
				this.vendorCompanies.find(
					vendor =>
						vendor.id ===
							+payeeData.vendorOrganizationId);

			payeeName = vendorInformation.data?.name?.legalName;
		}
		else if (payeeData.type === this.adjusterType)
		{
			const adjusterInformation =
				this.adjustingCompanies.find(
					adjuster =>
						adjuster.id ===
							+payeeData.adjustingOrganizationId);

			payeeName = adjusterInformation.data?.name?.legalName;
		}
		else
		{
			payeeName =
				StringHelper.toNameString(
					payeeData?.name?.firstName,
					payeeData?.name?.lastName);
		}

		const claimPaymentData: IEntityInstance =
			<IEntityInstance>
			{
				id: 0,
				entityType: ClaimConstants.claimEntityTypes.claimPayment,
				versionNumber: null,
				data: {
					status: this.pendingStatus,
					claimNumber: this.claim.data.claimNumber,
					payee: {
						id: payeeData.resourceIdentifier,
						name: payeeName,
						type: currentData.payee.type,
						address: {
							type: SharedTypeConstants.addressType.mailing,
							subType: null,
							address: currentData.payee.address.address,
							city: currentData.payee.address.city,
							state: currentData.payee.address.state,
							principalSubdivision: currentData.payee.address
								.principalSubdivision,
							postalCode: currentData.payee.address.postalCode,
							county: null,
							country: currentData.payee.address.country
						}
					}
				}
			};

		return claimPaymentData;
	}

	/**
	 * This will send the entity creation event.
	 *
	 * @param {IEntityType} entityCreationType
	 * The entity type to be created.
	 * @param {IEntityInstance} entityInstanceData
	 *  The entity instance data to be saved.
	 * @async
	 * @memberof AddClaimPaymentComponent
	 */
	private async createEntity(
		entityCreationType: IEntityType,
		entityInstanceData: IEntityInstance,
		entityParentTypeGroup: string,
		entityParentId: number,
		parameters: object): Promise<number>
	{
		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		const newEntityId: Promise<number> =
			this.createEntityInstance(
				entityCreationType,
				entityInstanceData,
				entityParentTypeGroup,
				entityParentId,
				parameters);

		return newEntityId;
	}

	/**
	 * Creates an entity instance and all entity relationships.
	 *
	 * @param {IEntityType} entityCreationType
	 * The entity type to be created.
	 * @param {IEntityInstance} entityInstanceData
	 *  The entity instance data to be saved.
	 * @returns {Promise<number>}
	 * The id of the newly created entity.
	 * @memberof AddClaimPaymentComponent
	 */
	private async createEntityInstance(
		entityCreationType: IEntityType,
		entityInstanceData: IEntityInstance,
		entityParentTypeGroup: string,
		entityParentId: number,
		parameters: object): Promise<number>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				entityCreationType.group;

		const createdEntityId: number =
			await this.entityInstanceApiService
				.createEntityInstance(
					entityInstanceData,
					entityParentTypeGroup,
					entityParentId,
					parameters);

		return createdEntityId;
	}

	/**
	 * This will navigate to the claim summary dashboard the claim id
	 * provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The claim entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof AddClaimPaymentComponent
	 */
	private async navigateToClaimPayment(
		entityId: number,
		group: string)
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @memberof AddClaimPaymentComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		const payeePromise: string =
			'return async function(field){let options=[];let payeeType=this.source.activeMenuItem.currentData.data.payeeType; '
			+ 'if(payeeType!=null){var entityInstanceApiService=this.source.resolver.resolveApiService(\"EntityInstanceApiService\"); '
			+ 'var claimsService=this.source.resolver.resolveClaims(\"ClaimsService\"); '
			+ 'entityInstanceApiService.entityInstanceTypeGroup=\"Claims\"; '
			+ 'let claim=await entityInstanceApiService.get(this.source.activeMenuItem.currentData.data.id); '
			+ 'let applicableParties=claim.data.involvedParties.filter(party=>party.type===payeeType); '
			+ 'if(payeeType==\"Adjuster\"){ '
			+ 'var adjusters=await claimsService.getClaimAdjusterOrganizations(claim.id); '
			+ 'applicableParties.forEach(party=>{const adjusterMatch=adjusters.filter(adjuster=>adjuster.id==party.adjustingOrganizationId); '
			+ 'options.push({label:adjusterMatch[0].data?.name?.legalName,value:party?.resourceIdentifier});});} '
			+ 'else if(payeeType==\"Vendor\"){entityInstanceApiService.entityInstanceTypeGroup=\"Organization.ClaimsVendors\"; '
			+ 'var vendors=await this.source.resolver.resolveStatic(\"ApiHelper\",\"getFullDataSet\",[entityInstanceApiService,\"\",\"\"]); '
			+ 'applicableParties.forEach(party=>{const vendorMatch=vendors.filter(vendor=>vendor.id==party.vendorOrganizationId); '
			+ 'options.push({label:vendorMatch[0].data?.name?.legalName,value:party?.resourceIdentifier});});} '
			+ 'else{applicableParties.forEach(party=>{options.push({label:party?.name?.firstName+\" \"+party?.name?.lastName,value:party?.resourceIdentifier});});} '
			+ 'if(options.length==0){field.props.disabled=true;field.props.placeholder=\"No Available Options\"; '
			+ 'field.formControl.setValue(null);field.formControl.updateValueAndValidity();}else{field.props.disabled=false; '
			+ 'field.props.placeholder=\"Select An Option\";}return options;}else{field.props.disabled=true;field.props.placeholder=\"No Available Options\"; '
			+ 'return options;}}';

		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.payee.type',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Payee Type',
						description: 'The payee type.',
						placeholder: AppConstants.placeholders.selectAnOption,
						required: true,
						options: [
							{
								value: 'Insured',
								label: 'Insured'
							},
							{
								value: 'Claimant',
								label: 'Claimant'
							},
							{
								value: 'Adjuster',
								label: 'Adjuster'
							},
							{
								value: 'Vendor',
								label: 'Vendor'
							},
							{
								value: 'PolicyInterest',
								label: 'Policy Interest'
							}
						],
						change:
							(field: FormlyFieldConfig,
								_event: any) =>
							{
								this.dynamicFormlyLayout[3]
									.formControl.setValue('USA');
								this.dynamicFormlyLayout[3]
									.formControl.updateValueAndValidity();
								this.dynamicFormlyLayout[3]
									.props.change(this.dynamicFormlyLayout[3]);

								this.dynamicFormlyLayout[5]
									.formControl.setValue(null);
								this.dynamicFormlyLayout[5]
									.formControl.updateValueAndValidity();

								this.dynamicFormlyLayout[6]
									.formControl.setValue(null);
								this.dynamicFormlyLayout[6]
									.formControl.updateValueAndValidity();

								this.dynamicFormlyLayout[7]
									.formControl.setValue(null);
								this.dynamicFormlyLayout[7]
									.formControl.updateValueAndValidity();

								this.dynamicFormlyLayout[8]
									.formControl.setValue(null);
								this.dynamicFormlyLayout[8]
									.formControl.updateValueAndValidity();

								this.dynamicFormlyLayout[9]
									.formControl.setValue(null);
								this.dynamicFormlyLayout[9]
									.formControl.updateValueAndValidity();

								if (!AnyHelper.isNullOrEmpty(
									field.formControl.value))
								{
									this.dynamicFormlyLayout[1]
										.formControl.setValue(null);
									this.dynamicFormlyLayout[1]
										.formControl.updateValueAndValidity();

									this.context.source.activeMenuItem
										.currentData.data.payeeType =
											field.formControl.value;

									this.dynamicFormlyLayout[1]
										.props.initializeDataOptions();
								}
								else
								{
									this.context.source.activeMenuItem
										.currentData.data.payeeType =
											null;
								}
							}
					},
					asyncValidators: {
						payeeTypeValidator: {
							expression: (
								_formControl: UntypedFormControl,
								field: FormlyFieldConfig) =>
								this.validPayeeType(field),
							message:
								AppConstants.empty
						}
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.payee.name',
					type: FormlyConstants.customControls.customDataSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Payee Name',
						description: 'The payee name.',
						placeholder: AppConstants.placeholders.selectAnOption,
						dataPromise: payeePromise,
						labelTemplate: '${item.label}',
						valueTemplate: '${item.value}',
						required: true,
						change: (field) =>
						{
							if(!AnyHelper.isNullOrEmpty(field.formControl.value))
							{
								const payeeInformation =
									this.claim.data.involvedParties.find(
										(party: any) =>
											party.resourceIdentifier ===
												field.formControl.value);

								let address: string;
								let city: string;
								let state: string;
								let principalSubdivision: string;
								let postalCode: string;
								let country: string;

								if (payeeInformation?.type === this.vendorType)
								{
									const vendorInformation =
										this.vendorCompanies.find(
											vendor =>
												vendor.id ===
													+payeeInformation
														.vendorOrganizationId);

									address =
										vendorInformation.data?.addresses[0]
											?.address;
									city =
										vendorInformation.data?.addresses[0]
											?.city;
									state =
										vendorInformation.data?.addresses[0]
											?.state;
									principalSubdivision =
										vendorInformation.data?.addresses[0]
											?.principalSubdivision;
									postalCode =
										vendorInformation.data?.addresses[0]
											?.postalCode;
									country =
										vendorInformation.data?.addresses[0]
											?.country;
								}
								else if (payeeInformation?.type
									=== this.adjusterType)
								{
									const adjusterInformation =
										this.adjustingCompanies.find(
											adjuster =>
												adjuster.id ===
													+payeeInformation
														.adjustingOrganizationId);

									address =
										adjusterInformation.data?.addresses[0]
											?.address;
									city =
										adjusterInformation.data?.addresses[0]
											?.city;
									state =
										adjusterInformation.data?.addresses[0]
											?.state;
									principalSubdivision =
										adjusterInformation.data?.addresses[0]
											?.principalSubdivision;
									postalCode =
										adjusterInformation.data?.addresses[0]
											?.postalCode;
									country =
										adjusterInformation.data?.addresses[0]
											?.country;
								}
								else
								{
									address = payeeInformation
										?.addresses[0]?.address;
									city = payeeInformation
										?.addresses[0]?.city;
									state = payeeInformation
										?.addresses[0]?.state;
									principalSubdivision = payeeInformation
										?.addresses[0]?.principalSubdivision;
									postalCode = payeeInformation
										?.addresses[0]?.postalCode;
									country = payeeInformation
										?.addresses[0]?.country;
								}

								this.dynamicFormlyLayout[3]
									.formControl.setValue(country);
								this.dynamicFormlyLayout[3]
									.formControl.updateValueAndValidity();
								this.dynamicFormlyLayout[3]
									.props.change(this.dynamicFormlyLayout[3]);

								this.dynamicFormlyLayout[5]
									.formControl.setValue(address);
								this.dynamicFormlyLayout[5]
									.formControl.updateValueAndValidity();

								this.dynamicFormlyLayout[6]
									.formControl.setValue(city);
								this.dynamicFormlyLayout[6]
									.formControl.updateValueAndValidity();

								this.dynamicFormlyLayout[7]
									.formControl.setValue(state);
								this.dynamicFormlyLayout[7]
									.formControl.updateValueAndValidity();

								this.dynamicFormlyLayout[8]
									.formControl.setValue(principalSubdivision);
								this.dynamicFormlyLayout[8]
									.formControl.updateValueAndValidity();

								this.dynamicFormlyLayout[9]
									.formControl.setValue(postalCode);
								this.dynamicFormlyLayout[9]
									.formControl.updateValueAndValidity();
							}
							else
							{
								this.dynamicFormlyLayout[3]
									.formControl.setValue('USA');
								this.dynamicFormlyLayout[3]
									.formControl.updateValueAndValidity();
								this.dynamicFormlyLayout[3]
									.props.change(this.dynamicFormlyLayout[3]);

								this.dynamicFormlyLayout[5]
									.formControl.setValue(null);
								this.dynamicFormlyLayout[5]
									.formControl.updateValueAndValidity();

								this.dynamicFormlyLayout[6]
									.formControl.setValue(null);
								this.dynamicFormlyLayout[6]
									.formControl.updateValueAndValidity();

								this.dynamicFormlyLayout[7]
									.formControl.setValue(null);
								this.dynamicFormlyLayout[7]
									.formControl.updateValueAndValidity();

								this.dynamicFormlyLayout[8]
									.formControl.setValue(null);
								this.dynamicFormlyLayout[8]
									.formControl.updateValueAndValidity();

								this.dynamicFormlyLayout[9]
									.formControl.setValue(null);
								this.dynamicFormlyLayout[9]
									.formControl.updateValueAndValidity();
							}
						}
					}
				},
				<FormlyFieldConfig>
				{
					"type": FormlyConstants.customControls.customSectionTitle,
					"props": {
						"label": "Mailing Address"
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.payee.address.country',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Country',
						description: 'The payee country.',
						placeholder: AppConstants.placeholders.selectAnOption,
						required: true,
						default: 'USA',
						options: [
							{
								"value": "ABW",
								"label": "Aruba"
							},
							{
								"value": "AFG",
								"label": "Afghanistan"
							},
							{
								"value": "AGO",
								"label": "Angola"
							},
							{
								"value": "AIA",
								"label": "Anguilla"
							},
							{
								"value": "ALA",
								"label": "Åland Islands"
							},
							{
								"value": "ALB",
								"label": "Albania"
							},
							{
								"value": "AND",
								"label": "Andorra"
							},
							{
								"value": "ARE",
								"label": "United Arab Emirates"
							},
							{
								"value": "ARG",
								"label": "Argentina"
							},
							{
								"value": "ARM",
								"label": "Armenia"
							},
							{
								"value": "ASM",
								"label": "American Samoa"
							},
							{
								"value": "ATA",
								"label": "Antarctica"
							},
							{
								"value": "ATF",
								"label": "French Southern Territories"
							},
							{
								"value": "ATG",
								"label": "Antigua and Barbuda"
							},
							{
								"value": "AUS",
								"label": "Australia"
							},
							{
								"value": "AUT",
								"label": "Austria"
							},
							{
								"value": "AZE",
								"label": "Azerbaijan"
							},
							{
								"value": "BDI",
								"label": "Burundi"
							},
							{
								"value": "BEL",
								"label": "Belgium"
							},
							{
								"value": "BEN",
								"label": "Benin"
							},
							{
								"value": "BES",
								"label": "Bonaire Sint Eustatius and Saba"
							},
							{
								"value": "BFA",
								"label": "Burkina Faso"
							},
							{
								"value": "BGD",
								"label": "Bangladesh"
							},
							{
								"value": "BGR",
								"label": "Bulgaria"
							},
							{
								"value": "BHR",
								"label": "Bahrain"
							},
							{
								"value": "BHS",
								"label": "Bahamas"
							},
							{
								"value": "BIH",
								"label": "Bosnia and Herzegovina"
							},
							{
								"value": "BLM",
								"label": "Saint Barthélemy"
							},
							{
								"value": "BLR",
								"label": "Belarus"
							},
							{
								"value": "BLZ",
								"label": "Belize"
							},
							{
								"value": "BMU",
								"label": "Bermuda"
							},
							{
								"value": "BOL",
								"label": "Bolivia"
							},
							{
								"value": "BRA",
								"label": "Brazil"
							},
							{
								"value": "BRB",
								"label": "Barbados"
							},
							{
								"value": "BRN",
								"label": "Brunei Darussalam"
							},
							{
								"value": "BTN",
								"label": "Bhutan"
							},
							{
								"value": "BVT",
								"label": "Bouvet Island"
							},
							{
								"value": "BWA",
								"label": "Botswana"
							},
							{
								"value": "CAF",
								"label": "Central African Republic"
							},
							{
								"value": "CAN",
								"label": "Canada"
							},
							{
								"value": "CCK",
								"label": "Cocos (Keeling) Islands"
							},
							{
								"value": "CHE",
								"label": "Switzerland"
							},
							{
								"value": "CHL",
								"label": "Chile"
							},
							{
								"value": "CHN",
								"label": "China"
							},
							{
								"value": "CIV",
								"label": "Côte d'Ivoire"
							},
							{
								"value": "CMR",
								"label": "Cameroon"
							},
							{
								"value": "COD",
								"label": "Congo Democratic Republic"
							},
							{
								"value": "COG",
								"label": "Congo"
							},
							{
								"value": "COK",
								"label": "Cook Islands"
							},
							{
								"value": "COL",
								"label": "Colombia"
							},
							{
								"value": "COM",
								"label": "Comoros"
							},
							{
								"value": "CPV",
								"label": "Cabo Verde"
							},
							{
								"value": "CRI",
								"label": "Costa Rica"
							},
							{
								"value": "CUB",
								"label": "Cuba"
							},
							{
								"value": "CUW",
								"label": "Curaçao"
							},
							{
								"value": "CXR",
								"label": "Christmas Island"
							},
							{
								"value": "CYM",
								"label": "Cayman Islands"
							},
							{
								"value": "CYP",
								"label": "Cyprus"
							},
							{
								"value": "CZE",
								"label": "Czechia"
							},
							{
								"value": "DEU",
								"label": "Germany"
							},
							{
								"value": "DJI",
								"label": "Djibouti"
							},
							{
								"value": "DMA",
								"label": "Dominica"
							},
							{
								"value": "DNK",
								"label": "Denmark"
							},
							{
								"value": "DOM",
								"label": "Dominican Republic"
							},
							{
								"value": "DZA",
								"label": "Algeria"
							},
							{
								"value": "ECU",
								"label": "Ecuador"
							},
							{
								"value": "EGY",
								"label": "Egypt"
							},
							{
								"value": "ERI",
								"label": "Eritrea"
							},
							{
								"value": "ESH",
								"label": "Western Sahara"
							},
							{
								"value": "ESP",
								"label": "Spain"
							},
							{
								"value": "EST",
								"label": "Estonia"
							},
							{
								"value": "ETH",
								"label": "Ethiopia"
							},
							{
								"value": "FIN",
								"label": "Finland"
							},
							{
								"value": "FJI",
								"label": "Fiji"
							},
							{
								"value": "FLK",
								"label": "Falkland Islands"
							},
							{
								"value": "FRA",
								"label": "France"
							},
							{
								"value": "FRO",
								"label": "Faroe Islands"
							},
							{
								"value": "FSM",
								"label": "Micronesia"
							},
							{
								"value": "GAB",
								"label": "Gabon"
							},
							{
								"value": "GBR",
								"label": "United Kingdom"
							},
							{
								"value": "GEO",
								"label": "Georgia"
							},
							{
								"value": "GGY",
								"label": "Guernsey"
							},
							{
								"value": "GHA",
								"label": "Ghana"
							},
							{
								"value": "GIB",
								"label": "Gibraltar"
							},
							{
								"value": "GIN",
								"label": "Guinea"
							},
							{
								"value": "GLP",
								"label": "Guadeloupe"
							},
							{
								"value": "GMB",
								"label": "Gambia"
							},
							{
								"value": "GNB",
								"label": "Guinea-Bissau"
							},
							{
								"value": "GNQ",
								"label": "Equatorial Guinea"
							},
							{
								"value": "GRC",
								"label": "Greece"
							},
							{
								"value": "GRD",
								"label": "Grenada"
							},
							{
								"value": "GRL",
								"label": "Greenland"
							},
							{
								"value": "GTM",
								"label": "Guatemala"
							},
							{
								"value": "GUF",
								"label": "French Guiana"
							},
							{
								"value": "GUM",
								"label": "Guam"
							},
							{
								"value": "GUY",
								"label": "Guyana"
							},
							{
								"value": "HKG",
								"label": "Hong Kong"
							},
							{
								"value": "HMD",
								"label": "Heard Island and McDonald Islands"
							},
							{
								"value": "HND",
								"label": "Honduras"
							},
							{
								"value": "HRV",
								"label": "Croatia"
							},
							{
								"value": "HTI",
								"label": "Haiti"
							},
							{
								"value": "HUN",
								"label": "Hungary"
							},
							{
								"value": "IDN",
								"label": "Indonesia"
							},
							{
								"value": "IMN",
								"label": "Isle of Man"
							},
							{
								"value": "IND",
								"label": "India"
							},
							{
								"value": "IOT",
								"label": "British Indian Ocean Territory"
							},
							{
								"value": "IRL",
								"label": "Ireland"
							},
							{
								"value": "IRN",
								"label": "Iran"
							},
							{
								"value": "IRQ",
								"label": "Iraq"
							},
							{
								"value": "ISL",
								"label": "Iceland"
							},
							{
								"value": "ISR",
								"label": "Israel"
							},
							{
								"value": "ITA",
								"label": "Italy"
							},
							{
								"value": "JAM",
								"label": "Jamaica"
							},
							{
								"value": "JEY",
								"label": "Jersey"
							},
							{
								"value": "JOR",
								"label": "Jordan"
							},
							{
								"value": "JPN",
								"label": "Japan"
							},
							{
								"value": "KAZ",
								"label": "Kazakhstan"
							},
							{
								"value": "KEN",
								"label": "Kenya"
							},
							{
								"value": "KGZ",
								"label": "Kyrgyzstan"
							},
							{
								"value": "KHM",
								"label": "Cambodia"
							},
							{
								"value": "KIR",
								"label": "Kiribati"
							},
							{
								"value": "KNA",
								"label": "Saint Kitts and Nevis"
							},
							{
								"value": "KOR",
								"label": "Korea Republic of"
							},
							{
								"value": "KWT",
								"label": "Kuwait"
							},
							{
								"value": "LAO",
								"label": "Lao"
							},
							{
								"value": "LBN",
								"label": "Lebanon"
							},
							{
								"value": "LBR",
								"label": "Liberia"
							},
							{
								"value": "LBY",
								"label": "Libya"
							},
							{
								"value": "LCA",
								"label": "Saint Lucia"
							},
							{
								"value": "LIE",
								"label": "Liechtenstein"
							},
							{
								"value": "LKA",
								"label": "Sri Lanka"
							},
							{
								"value": "LSO",
								"label": "Lesotho"
							},
							{
								"value": "LTU",
								"label": "Lithuania"
							},
							{
								"value": "LUX",
								"label": "Luxembourg"
							},
							{
								"value": "LVA",
								"label": "Latvia"
							},
							{
								"value": "MAC",
								"label": "Macao"
							},
							{
								"value": "MAF",
								"label": "Saint Martin"
							},
							{
								"value": "MAR",
								"label": "Morocco"
							},
							{
								"value": "MCO",
								"label": "Monaco"
							},
							{
								"value": "MDA",
								"label": "Moldova"
							},
							{
								"value": "MDG",
								"label": "Madagascar"
							},
							{
								"value": "MDV",
								"label": "Maldives"
							},
							{
								"value": "MEX",
								"label": "Mexico"
							},
							{
								"value": "MHL",
								"label": "Marshall Islands"
							},
							{
								"value": "MKD",
								"label": "North Macedonia"
							},
							{
								"value": "MLI",
								"label": "Mali"
							},
							{
								"value": "MLT",
								"label": "Malta"
							},
							{
								"value": "MMR",
								"label": "Myanmar"
							},
							{
								"value": "MNE",
								"label": "Montenegro"
							},
							{
								"value": "MNG",
								"label": "Mongolia"
							},
							{
								"value": "MNP",
								"label": "Northern Mariana Islands"
							},
							{
								"value": "MOZ",
								"label": "Mozambique"
							},
							{
								"value": "MRT",
								"label": "Mauritania"
							},
							{
								"value": "MSR",
								"label": "Montserrat"
							},
							{
								"value": "MTQ",
								"label": "Martinique"
							},
							{
								"value": "MUS",
								"label": "Mauritius"
							},
							{
								"value": "MWI",
								"label": "Malawi"
							},
							{
								"value": "MYS",
								"label": "Malaysia"
							},
							{
								"value": "MYT",
								"label": "Mayotte"
							},
							{
								"value": "NAM",
								"label": "Namibia"
							},
							{
								"value": "NCL",
								"label": "New Caledonia"
							},
							{
								"value": "NER",
								"label": "Niger"
							},
							{
								"value": "NFK",
								"label": "Norfolk Island"
							},
							{
								"value": "NGA",
								"label": "Nigeria"
							},
							{
								"value": "NIC",
								"label": "Nicaragua"
							},
							{
								"value": "NIU",
								"label": "Niue"
							},
							{
								"value": "NLD",
								"label": "Netherlands"
							},
							{
								"value": "NOR",
								"label": "Norway"
							},
							{
								"value": "NPL",
								"label": "Nepal"
							},
							{
								"value": "NRU",
								"label": "Nauru"
							},
							{
								"value": "NZL",
								"label": "New Zealand"
							},
							{
								"value": "OMN",
								"label": "Oman"
							},
							{
								"value": "PAK",
								"label": "Pakistan"
							},
							{
								"value": "PAN",
								"label": "Panama"
							},
							{
								"value": "PCN",
								"label": "Pitcairn"
							},
							{
								"value": "PER",
								"label": "Peru"
							},
							{
								"value": "PHL",
								"label": "Philippines"
							},
							{
								"value": "PLW",
								"label": "Palau"
							},
							{
								"value": "PNG",
								"label": "Papua New Guinea"
							},
							{
								"value": "POL",
								"label": "Poland"
							},
							{
								"value": "PRI",
								"label": "Puerto Rico"
							},
							{
								"value": "PRK",
								"label": "Korea Democratic People's Republic"
							},
							{
								"value": "PRT",
								"label": "Portugal"
							},
							{
								"value": "PRY",
								"label": "Paraguay"
							},
							{
								"value": "PSE",
								"label": "Palestine"
							},
							{
								"value": "PYF",
								"label": "French Polynesia"
							},
							{
								"value": "QAT",
								"label": "Qatar"
							},
							{
								"value": "REU",
								"label": "Réunion"
							},
							{
								"value": "ROU",
								"label": "Romania"
							},
							{
								"value": "RUS",
								"label": "Russian Federation"
							},
							{
								"value": "RWA",
								"label": "Rwanda"
							},
							{
								"value": "SAU",
								"label": "Saudi Arabia"
							},
							{
								"value": "SDN",
								"label": "Sudan"
							},
							{
								"value": "SEN",
								"label": "Senegal"
							},
							{
								"value": "SGP",
								"label": "Singapore"
							},
							{
								"value": "SGS",
								"label": "South Georgia and South Sandwich Islands"
							},
							{
								"value": "SHN",
								"label": "Saint Helena Ascension and Tristan da Cunha"
							},
							{
								"value": "SJM",
								"label": "Svalbard and Jan Mayen"
							},
							{
								"value": "SLB",
								"label": "Solomon Islands"
							},
							{
								"value": "SLE",
								"label": "Sierra Leone"
							},
							{
								"value": "SLV",
								"label": "El Salvador"
							},
							{
								"value": "SMR",
								"label": "San Marino"
							},
							{
								"value": "SOM",
								"label": "Somalia"
							},
							{
								"value": "SPM",
								"label": "Saint Pierre and Miquelon"
							},
							{
								"value": "SRB",
								"label": "Serbia"
							},
							{
								"value": "SSD",
								"label": "South Sudan"
							},
							{
								"value": "STP",
								"label": "Sao Tome and Principe"
							},
							{
								"value": "SUR",
								"label": "Suriname"
							},
							{
								"value": "SVK",
								"label": "Slovakia"
							},
							{
								"value": "SVN",
								"label": "Slovenia"
							},
							{
								"value": "SWE",
								"label": "Sweden"
							},
							{
								"value": "SWZ",
								"label": "Eswatini"
							},
							{
								"value": "SXM",
								"label": "Sint Maarten"
							},
							{
								"value": "SYC",
								"label": "Seychelles"
							},
							{
								"value": "SYR",
								"label": "Syrian Arab Republic"
							},
							{
								"value": "TCA",
								"label": "Turks and Caicos Islands"
							},
							{
								"value": "TCD",
								"label": "Chad"
							},
							{
								"value": "TGO",
								"label": "Togo"
							},
							{
								"value": "THA",
								"label": "Thailand"
							},
							{
								"value": "TJK",
								"label": "Tajikistan"
							},
							{
								"value": "TKL",
								"label": "Tokelau"
							},
							{
								"value": "TKM",
								"label": "Turkmenistan"
							},
							{
								"value": "TLS",
								"label": "Timor-Leste"
							},
							{
								"value": "TON",
								"label": "Tonga"
							},
							{
								"value": "TTO",
								"label": "Trinidad and Tobago"
							},
							{
								"value": "TUN",
								"label": "Tunisia"
							},
							{
								"value": "TUR",
								"label": "Türkiye"
							},
							{
								"value": "TUV",
								"label": "Tuvalu"
							},
							{
								"value": "TWN",
								"label": "Taiwan"
							},
							{
								"value": "TZA",
								"label": "Tanzania"
							},
							{
								"value": "UGA",
								"label": "Uganda"
							},
							{
								"value": "UKR",
								"label": "Ukraine"
							},
							{
								"value": "UMI",
								"label": "United States Minor Outlying Islands"
							},
							{
								"value": "URY",
								"label": "Uruguay"
							},
							{
								"value": "USA",
								"label": "United States of America"
							},
							{
								"value": "UZB",
								"label": "Uzbekistan"
							},
							{
								"value": "VAT",
								"label": "Holy See"
							},
							{
								"value": "VCT",
								"label": "Saint Vincent and the Grenadines"
							},
							{
								"value": "VEN",
								"label": "Venezuela"
							},
							{
								"value": "VGB",
								"label": "Virgin Islands British"
							},
							{
								"value": "VIR",
								"label": "Virgin Islands U.S."
							},
							{
								"value": "VNM",
								"label": "Viet Nam"
							},
							{
								"value": "VUT",
								"label": "Vanuatu"
							},
							{
								"value": "WLF",
								"label": "Wallis and Futuna"
							},
							{
								"value": "WSM",
								"label": "Samoa"
							},
							{
								"value": "YEM",
								"label": "Yemen"
							},
							{
								"value": "ZAF",
								"label": "South Africa"
							},
							{
								"value": "ZMB",
								"label": "Zambia"
							},
							{
								"value": "ZWE",
								"label": "Zimbabwe"
							}
						],
						change: (field) =>
						{
							const city =
								field.parent.fieldGroup[6];
							const state =
								field.parent.fieldGroup[7];
							const principalSubdivision =
								field.parent.fieldGroup[8];
							if (field.formControl.value !== "USA")
							{
								state.props.required = false;
								state.hide = true;
								state.formControl.setValue(null);
								state.formControl
									.updateValueAndValidity();
								principalSubdivision.hide = false;
								principalSubdivision.props.required = true;
								city.props.required = false;
							}
							else
							{
								principalSubdivision.props.required = false;
								principalSubdivision.hide = true;
								principalSubdivision.formControl
									.setValue(null);
								principalSubdivision.formControl
									.updateValueAndValidity();
								state.hide = false;
								state.props.required = true;
								city.props.required = true;
							}
						}
					}
				},
				<FormlyFieldConfig>
				{
					"wrappers": [
						FormlyConstants.customControls.customEmptyWrapper
					],
					"templateOptions": {}
				},
				<FormlyFieldConfig>
				{
					key: 'data.payee.address.address',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Address',
						description: 'The payee mailing address.',
						placeholder: '',
						required: true
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.payee.address.city',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'City',
						description: 'The payee mailing city.',
						placeholder: '',
						required: true
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.payee.address.state',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'State',
						description: 'The payee mailing state.',
						placeholder: AppConstants.placeholders.selectAnOption,
						required: true,
						default: null,
						options: [
							{
								value: 'AL',
								label: 'AL'
							},
							{
								value: 'AK',
								label: 'AK'
							},
							{
								value: 'AZ',
								label: 'AZ'
							},
							{
								value: 'AR',
								label: 'AR'
							},
							{
								value: 'CA',
								label: 'CA'
							},
							{
								value: 'CO',
								label: 'CO'
							},
							{
								value: 'CT',
								label: 'CT'
							},
							{
								value: 'DE',
								label: 'DE'
							},
							{
								value: 'DC',
								label: 'DC'
							},
							{
								value: 'FL',
								label: 'FL'
							},
							{
								value: 'GA',
								label: 'GA'
							},
							{
								value: 'HI',
								label: 'HI'
							},
							{
								value: 'ID',
								label: 'ID'
							},
							{
								value: 'IL',
								label: 'IL'
							},
							{
								value: 'IN',
								label: 'IN'
							},
							{
								value: 'IA',
								label: 'IA'
							},
							{
								value: 'KS',
								label: 'KS'
							},
							{
								value: 'KY',
								label: 'KY'
							},
							{
								value: 'LA',
								label: 'LA'
							},
							{
								value: 'ME',
								label: 'ME'
							},
							{
								value: 'MD',
								label: 'MD'
							},
							{
								value: 'MA',
								label: 'MA'
							},
							{
								value: 'MI',
								label: 'MI'
							},
							{
								value: 'MN',
								label: 'MN'
							},
							{
								value: 'MS',
								label: 'MS'
							},
							{
								value: 'MO',
								label: 'MO'
							},
							{
								value: 'MT',
								label: 'MT'
							},
							{
								value: 'NE',
								label: 'NE'
							},
							{
								value: 'NV',
								label: 'NV'
							},
							{
								value: 'NH',
								label: 'NH'
							},
							{
								value: 'NJ',
								label: 'NJ'
							},
							{
								value: 'NM',
								label: 'NM'
							},
							{
								value: 'NY',
								label: 'NY'
							},
							{
								value: 'NC',
								label: 'NC'
							},
							{
								value: 'ND',
								label: 'ND'
							},
							{
								value: 'OH',
								label: 'OH'
							},
							{
								value: 'OK',
								label: 'OK'
							},
							{
								value: 'OR',
								label: 'OR'
							},
							{
								value: 'PA',
								label: 'PA'
							},
							{
								value: 'RI',
								label: 'RI'
							},
							{
								value: 'SC',
								label: 'SC'
							},
							{
								value: 'SD',
								label: 'SD'
							},
							{
								value: 'TN',
								label: 'TN'
							},
							{
								value: 'TX',
								label: 'TX'
							},
							{
								value: 'UT',
								label: 'UT'
							},
							{
								value: 'VT',
								label: 'VT'
							},
							{
								value: 'VA',
								label: 'VA'
							},
							{
								value: 'WA',
								label: 'WA'
							},
							{
								value: 'WV',
								label: 'WV'
							},
							{
								value: 'WI',
								label: 'WI'
							},
							{
								value: 'WY',
								label: 'WY'
							},
							{
								value: 'AS',
								label: 'AS'
							},
							{
								value: 'GU',
								label: 'GU'
							},
							{
								value: 'MP',
								label: 'MP'
							},
							{
								value: 'PR',
								label: 'PR'
							},
							{
								value: 'UM',
								label: 'UM'
							},
							{
								value: 'VI',
								label: 'VI'
							}
						]
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.payee.address.principalSubdivision',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Principal Subdivision',
						description: 'The payee mailing principal subdivision.',
						placeholder: ''
					}
				},
				<FormlyFieldConfig>
				{
					key: 'data.payee.address.postalCode',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					props: {
						label: 'Zip',
						description: 'The payee mailing zip.',
						placeholder: '',
						required: true
					}
				}
			];
	}
}